import { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';
import { NavLinks } from './NavLinks';

export function NavMenu({
  setMobileMenuOpen,
}: {
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
      {matches ? (
        <IconButton
          onClick={() => {
            setMobileMenuOpen((s) => !s);
          }}
        >
          <MenuIcon htmlColor="#000" />
        </IconButton>
      ) : (
        <NavLinks setMobileMenuOpen={setMobileMenuOpen} mobile={matches} />
      )}
    </Box>
  );
}
