import { useEffect, useState, useRef } from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import logo from 'assets/images/logo.svg';
// import Image from 'next/image';
import { MobileMenu } from './MobileMenu';
import { NavMenu } from './NavMenu';
import Link from 'components/Link';

export function Nav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const headerRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!matches) {
      setMobileMenuOpen(false);
    }
  }, [matches]);

  useEffect(() => {
    document.addEventListener('click', handleCloseToggleMenu);
    return () => {
      document.removeEventListener('click', handleCloseToggleMenu);
    };
  }, []);

  const handleCloseToggleMenu = (e: Event) => {
    if (headerRef.current && !headerRef.current.contains(e.target as Element)) {
      setMobileMenuOpen(false);
    }
  };

  return (
    <Box
      ref={headerRef}
      sx={{
        backgroundColor: '#F2FFFB',
        boxShadow: '0px -1px 4px #b3e3d6',
        position: 'sticky',
        top: 0,
        zIndex: 100,
      }}
    >
      <Box
        sx={{
          maxWidth: 1280,
          py: {
            md: '20px',
            xs: '10px',
          },
          px: {
            md: '60px',
            xs: '20px',
          },
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <Box
          sx={{
            width: {
              sm: 75,
              xs: 45.531,
            },
            height: {
              sm: 50,
              xs: 36.422,
            },
            display: 'flex',
            alignItems: 'center',
            '& img': {
              width: '100%',
            },
          }}
        >
          <Link href="/">
            <img src={logo.src} alt="pod logo" />
          </Link>
        </Box>
        <NavMenu setMobileMenuOpen={setMobileMenuOpen} />
        {mobileMenuOpen && <MobileMenu setMobileMenuOpen={setMobileMenuOpen} />}
      </Box>
    </Box>
  );
}
