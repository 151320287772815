import { Checkbox } from '@mui/material';
import { withStyles } from '@mui/styles';

export function CustomCheckBox({ color, checkedColor, ...props }) {
  const StyledCheckBox = withStyles((_theme) => ({
    root: {
      color: color,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&$checked': {
        color: checkedColor,
      },
    },
    checked: {},
  }))(Checkbox);
  return <StyledCheckBox {...props} />;
}
