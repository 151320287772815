// import { Chip, ChipProps, SxProps, useTheme } from '@mui/material';

// interface CustomChipProps {
//   active: boolean;
//   sx?: SxProps;
// }

// export const CustomChip = ({
//   active,
//   sx,
//   ...otherProps
// }: CustomChipProps & ChipProps) => {
//   const theme = useTheme();
//   return (
//     <Chip
//       color={active ? 'primary' : 'secondary'}
//       {...otherProps}
//       sx={{
//         fontWeight: 500,
//         fontSize: 16,
//         p: 2,
//         color: active ? '#fff' : theme.palette.primary.main,
//         ...sx,
//       }}
//     />
//   );
// };

import { Chip, SxProps, useTheme } from '@mui/material';

interface CustomChipProps {
  active: boolean;
  sx?: SxProps;
}

export const CustomChip = ({
  active,
  sx,
  ...otherProps
}: CustomChipProps & any) => {
  const theme = useTheme();
  return (
    <Chip
      color={active ? 'primary' : 'secondary'}
      {...otherProps}
      sx={{
        fontWeight: 500,
        fontSize: 16,
        p: 2,
        color: active ? '#fff' : theme.palette.primary.main,
        ...sx,
      }}
    />
  );
};
