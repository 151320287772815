import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#008B5E',
      light: '#04B078',
    },
    secondary: {
      dark: '#BDFAE6',
      main: '#D9FBF3',
      light: 'rgba(242, 255, 251, 0.98)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1080,
      xl: 1920,
    },
  },
});

export default theme;
