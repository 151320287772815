import { TextField, TextFieldProps } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { styled } from '@mui/system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ChangeEvent } from 'react';

interface ICustomTextField {
  name: string;
  label?: string;
  control?: Control<any, object>;
  rules?: Record<any, any>;
  variant?: TextFieldProps['variant'];
  transform?: {
    input: (value: any) => any;
    output: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => string;
  };
  customOnChange?: any;
}

export const StyledCustomTextField = styled(TextField)(() => ({
  '& input': {
    fontSize: 14,
    paddingTop: 12,
    paddingBottom: 12,
  },
  '& .MuiInputBase-root:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DFDFDF',
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#DFDFDF',
  },
  '& label[data-shrink=false]': {
    color: '#111111 !important',
    opacity: 0.9,
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DFDFDF',
    },
    '&:hover fieldset': {
      borderColor: '#DFDFDF',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#DFDFDF',
    },
  },
  '& label.Mui-focused': {
    // color: '#111111',
    // opacity: 0.6,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& label Mui-error': {
    color: 'green',
  },

  '& .MuiOutlinedInput-root.Mui-focused': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#DFDFDF',
      borderWidth: 1,
    },
  },
  '& .MuiInputLabel-root.MuiError label': {
    color: '#DFDFDF',
    '& span': {
      color: '#DFDFDF',
    },
  },
  '& .MuiInput-root.MuiInput-underline': {
    '&::before': {
      borderBottom: '1px solid rgba(223, 223, 223, 0.5)',
      // borderBottom: '1px solid orangered',
      content: '""',
    },
    // '&::after': {
    //   content: '""',
    //   borderBottom: '1px solid rgba(223, 223, 223, 0.5)',
    // },
    '&:hover': {
      '&::before': {
        borderBottom: '1px solid rgba(223, 223, 223, 0.5)',
        content: '""',
      },
    },
    // '&:focus': {
    //   '&::after': {
    //     borderBottom: '1px solid rgba(223, 223, 223, 0.5)',
    //     content: '""',
    //   },
    // },
    '&::after': {
      content: 'none',
    },
  },

  // '& .MuiInput-underline:hover': {
  //   '&::before': {
  //     borderBottom: '1px solid rgba(223, 223, 223, 0.5)',
  //     content: '""',
  //   },
  // },
}));

export const HelperText = ({
  text,
  variant,
}: {
  text: string;
  variant?: TextFieldProps['variant'];
}) => {
  return (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: variant === 'standard' ? 0 : '-0.75rem',
      }}
    >
      <ErrorOutlineIcon color="error" sx={{ fontSize: '0.75rem', mr: 0.4 }} />
      <span>{text}</span>
    </span>
  );
};

export const CustomTextFieldWithoutControl = ({
  variant,
  error,
  ...otherProps
}: TextFieldProps & { error?: any }) => (
  <StyledCustomTextField
    // {...field}
    // error={!!error?.message}
    // label={label}
    variant={variant}
    helperText={
      error?.message ? (
        <HelperText text={error.message} variant={variant} />
      ) : (
        ' '
      )
    }
    {...otherProps}
    // onChange={(e) => {
    //   console.log('onChange');
    // }}
    // {...(customOnChange ? { onChange: customOnChange } : {})}
    // {...(transform ? { value: transform.input(field.value) } : {})}
    // {...(transform
    //   ? { onChange: (e) => field.onChange(transform.output(e)) }
    //   : {})}
  />
);

export const CustomTextField = ({
  name,
  label,
  control,
  rules,
  variant,
  customOnChange,
  transform,
  ...otherProps
}: ICustomTextField & TextFieldProps) => {
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledCustomTextField
          {...field}
          error={!!error?.message}
          label={label}
          variant={variant}
          helperText={
            error?.message ? (
              <HelperText text={error.message} variant={variant} />
            ) : (
              ' '
            )
          }
          size="small"
          {...otherProps}
          // onChange={(e) => {
          //   console.log('onChange');
          // }}
          {...(customOnChange
            ? {
                onChange: (e) => field.onChange(customOnChange(e.target.value)),
              }
            : {})}
          {...(transform ? { value: transform.input(field.value) } : {})}
          {...(transform
            ? { onChange: (e) => field.onChange(transform.output(e)) }
            : {})}
        />
      )}
    />
  );
};
