import { Typography } from '@mui/material';
import { SxProps, TypographyProps } from '@mui/system';
import { ElementType, ReactText } from 'react';

interface LetterSpacedHeadingProps {
  component: ElementType<any>;
  children: ReactText;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
  sx?: SxProps;
}

export const LetterSpacedHeading = ({
  component,
  children,
  align,
  fontSize,
  fontWeight,
  letterSpacing,
  sx,
  ...otherProps
}: LetterSpacedHeadingProps & TypographyProps) => {
  return (
    <Typography
      component={component}
      variant="h3"
      align={align || 'center'}
      {...otherProps}
      // fontSize={fontSize || '30px'}
      fontWeight={fontWeight || 'bold'}
      lineHeight="34px"
      // letterSpacing={letterSpacing || '0.3em'}
      sx={{
        fontSize: {
          xs: 25,
          md: 30,
        },
        letterSpacing: {
          xs: '0.1em',
          md: '0.3em',
        },
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
