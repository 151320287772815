import { Box } from '@mui/system';
import { FC } from 'react';
import Footer from './Footer';
import { Nav } from './Nav';

const Layout: FC = ({ children }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Nav />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
