import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { NavLinks } from './NavLinks';

export function MobileMenu({
  setMobileMenuOpen,
}: {
  setMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <Box
      sx={{
        backgroundColor: '#F2FFFB',
        flexBasis: '100vw',
      }}
    >
      <NavLinks setMobileMenuOpen={setMobileMenuOpen} mobile={true} />
    </Box>
  );
}
